// ** MUI Imports
import React, { useEffect } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { FormControl, Select, OutlinedInput, MenuItem, InputBase, ToggleButtonGroup, ToggleButton } from '@mui/material'

import { SelectChangeEvent } from '@mui/material/Select'
import { useState } from 'react'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
// ** Icon Imports
import Icon from 'src/@core/components/icon'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import Brightness3Icon from '@mui/icons-material/Brightness3'
// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
// ** Hook Import
import { useAuth } from 'src/hooks/useAuth'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  changeTheme,
  updateAllProjects,
  updateSelectedEnviornment,
  updateSelectedProject,
  updateSelectedProjectDetails
} from 'src/redux/user/userSlice'
import { Dialog } from '@mui/material'
import { useAxios } from 'src/hooks/useAxios'
import ClientsDropdownMenu from 'src/views/client/clientsDropDownMenu'
import ProjectDetails from 'src/views/overview/ProjectDetails'
import SelectTechstack from 'src/views/overview/SelectTechstack'
import { RootState } from 'src/redux/store'
import { ProjectType } from 'src/types/apps/userTypes'
import toast from 'react-hot-toast'
import { Switch } from '@headlessui/react'
import { useSettings } from 'src/@core/hooks/useSettings'
import { ProjectDetailsForm } from 'src/types'
import WithPermission from 'src/pages/components/hoc/WithPermission'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}
interface Project {
  id: string
  environments: Record<string, { id: string }>
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const names = [
  { name: 'sandbox', color: 'bg-red-600' },
  { name: 'live', color: 'bg-green-500' }
]
interface projectDetails {
  id?: string
  name: string
  imageUrl: string
  techStack: string
  title: string
  button: string
  whitelistedOrigin: string[]
}

const defaultProjectDetails = {
  name: '',
  imageUrl: '',
  techStack: '',
  title: 'Create New Project',
  button: 'Create Project',
  whitelistedOrigin: []
}
const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const userData = useSelector((state: any) => state.user.userProfileData)
  console.log('userData', userData)

  // ** Hook
  const auth = useAuth()
  const theme = useTheme()
  const environment = useSelector((state: RootState) => state.user.selectedEnviornment)
  const axiosInstance = useAxios()
  const dispatch = useDispatch()

  //Selectors
  const allProjects = useSelector((state: RootState) => state.user.allProjects)
  const selectedProject = useSelector((state: RootState) => state.user.selectedProject)

  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = React.useRef<HTMLDivElement | null>(null)

  const toggleDropdown = () => setShowDropdown(false)
  const openDropDown = () => setShowDropdown(true)

  const [openSelectTech, setOpenSelectTech] = useState(false)
  const [openProjectDetails, setOpenProjectDetails] = useState(false)
  const [variant, setVariant] = useState("");
  const [darkMode,setDarkMode] = useState(false);

  const [projectDetails, setProjectDetails] = useState<ProjectDetailsForm>(defaultProjectDetails)

  useEffect(() => {
    if (openProjectDetails === false) {
      setProjectDetails(defaultProjectDetails)
    }
  }, [openProjectDetails])

  const {mode}= settings;

  useEffect(()=>{
    const darkmode= mode == "dark"? true:false
    setDarkMode(darkmode)
  },[mode])

  const handleChange = (): void => {
    setDarkMode(!darkMode);
    saveSettings({ ...settings, "mode": darkMode==true ? "dark":"light" })
  }


  const handleEnvironmentChange = (event: any) => {
    const selectedEnvironment = event.target.value
    dispatch(updateSelectedEnviornment(selectedEnvironment))
  }

  const handleSelectProjectDrop = (project: ProjectType, index: number) => {
    dispatch(updateSelectedProject(project))
    toggleDropdown()
    //@ts-ignore
    const environmentId = project.environments?.[environment]?.id
    console.log('environment', environmentId)
    if (environmentId) {
      dispatch(updateSelectedProjectDetails(environmentId))
    } else {
      console.error('Environment ID not found for the selected project.')
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  useEffect(() => {
    if (userData?.role === undefined) return

    if (userData?.organization?.createdFirstProject !== true) {
      setOpenSelectTech(true)
      setVariant('FirstProject')
    } else {
      setOpenSelectTech(false)
      setVariant('')
    }
  }, [userData])

  //  add Project

  const handleCloseSelectTech = (event: any, reason: any) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    setOpenSelectTech(false)
  }
  const handleBack = (event: any, reason: any) => {
    // setOpenSelectTech(true)
    console.log('Reason', reason)
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      if (variant === 'FirstProject') return
    }
    setProjectDetails({
      name: '',
      imageUrl: '',
      techStack: '',
      title: 'Create New Project',
      button: 'Create Project',
      whitelistedOrigin: []
    })
    setOpenProjectDetails(false)
  }

  const fetchProjectData = async () => {
    try {
      const response = await auth.getProjectsData()
      if (response.success) {
        dispatch(updateAllProjects(response.response))
        dispatch(updateSelectedProject(response.response[0]))
      }
    } catch (error) {
      toast(`${error}`)
    }
  }

  useEffect(() => {
    fetchProjectData()
  }, [axiosInstance])

  // Dark mode
  const storedSettings = JSON.parse(window.localStorage.getItem('settings') || '{}')

  useEffect(() => {
    dispatch(changeTheme(storedSettings.mode))
  }, [storedSettings])
  console.log('storedSettings', storedSettings.mode)
  // const mode = useSelector((state: RootState) => state.user.darkMode)
  // console.log("mode",mode);

  return (
    <div className='w-[97%] fixed top-0 h-[100%] px- dark:bg-[#24303f] bg-white  justify-between items-center inline-flex'>
      <div className='w-[50%] self-stretch px- justify-start items-center gap-1.5 flex cursor-pointer'>
        {userData?.role && (
          <>
            {userData?.role?.name == 'triaadmin' ? (
              <ClientsDropdownMenu />
            ) : (
              <>
                {allProjects.length > 0 ? (
                  <>
                    <div ref={dropdownRef} className='relative dark:bg-[#24303f]  w-[13rem]  '>
                      <div
                        onClick={openDropDown}
                        className='bg-white  flex px- dark:bg-[#24303f] justify-between  p-2 w-[13rem] rounded-md  focus:outline-none focus:ring-0 focus:shadow-none '
                      >
                        {selectedProject?.name}
                        <div>
                          <img className='h-1.5 mb-1' src='/images/logos/arrow_up_sm.png' alt='' />
                          <img className='h-1.5' src='/images/logos/arrow_down_sm.png' alt='' />
                        </div>
                      </div>

                      {showDropdown && (
                        <div className='absolute mt-1 bg-white px-2 dark:bg-[#24303f]  shadow-md rounded-md w-[100%]'>
                          {/* Map over dropdownOptions array to render options */}
                          <div className='max-h-[15rem] overflow-auto custom-scrollbar'>
                            {allProjects.map((project: ProjectType, index: number) => (
                              <a
                                key={index}
                                href='#'
                                onClick={e => {
                                  handleSelectProjectDrop(project, index)
                                }}
                                //  onChange={(e)=>handleSelectProject(e)}
                                className='block px-2 py-2 text-[14px] font-normal dark:text-[#b1b1b6]   text-gray-600 dark:hover:bg-gray-500  hover:bg-gray-100  '
                              >
                                {project.name}
                              </a>
                            ))}
                          </div>
                          <WithPermission resource='project' action='create'>
                          <div className='flex  h-8 w-[100%] mb-2 mt-2   text-md'>
                            <div
                              className=' gap-3 flex justify-start  text-white bg-violet-500 px-2 items-center  w-[100%] rounded-md'
                              onClick={() => {
                                setOpenSelectTech(true)
                                setShowDropdown(false)
                              }}
                            >
                              <AddOutlinedIcon className=' ' style={{ fontSize: '1rem' }} />{' '}
                              <div className='flex  right-0'> Add Project</div>
                            </div>
                          </div>
                          </WithPermission>
                        </div>
                      )}
                    </div>
                    <div className='w-[1px] mr-1  h-[100%] dark:bg-[#515151] bg-[#bdbbbb] '></div>

                    <div>
                      <FormControl
                        className={` lg:w-[15rem]  md:w-[11rem]  dark:text-[#eaeaee]  ${
                          environment === 'sandbox'
                            ? ' dark:bg-[#382618]  bg-red-200'
                            : environment === 'live'
                            ? 'bg-green-200 dark:bg-[#193e23]  '
                            : ''
                        } rounded-lg p-6 `}
                        // sx={{ width: '10rem' }}
                      >
                        <Select
                          className='h-[43px] lg:w-[15rem] md:w-[12rem] '
                          displayEmpty
                          onChange={handleEnvironmentChange}
                          input={<InputBase sx={{ padding: '8px' }} />}
                          //  input={<OutlinedInput sx={{}} className=' focus:outline-none focus:ring-0 focus:shadow-none' />}
                          renderValue={selected => {
                            return (
                              <div
                                className={`flex  ${
                                  environment === 'sandbox'
                                    ? ' dark:text-[#F6771B] text-red-400'
                                    : environment === 'live'
                                    ? 'text-green-500 '
                                    : ''
                                } items-center w-[100%] h-[100%]  `}
                              >
                                <div
                                  className={`w-2 h-2   ${
                                    environment === 'sandbox'
                                      ? 'dark:bg-[#F6771B]   bg-red-400 text-[#F6771B]'
                                      : environment === 'live'
                                      ? 'bg-green-500 '
                                      : ''
                                  } rounded-lg mr-4`}
                                ></div>

                                {environment}
                              </div>
                            )
                          }}
                          MenuProps={{
                            PaperProps: {
                              className: 'lg:w-[15rem] md:w-[12rem] ',
                              style: {}
                            }
                          }}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {names.map(name => (
                            <MenuItem key={name.name} value={name.name}>
                              <div className='flex  items-center '>
                                <div className={`w-2 h-2 ${name.color} rounded-lg mr-4 `}></div>
                                {name.name}
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className='w-[1px] ml-1  h-[100%] dark:bg-[#515151] bg-[#bdbbbb]'></div>
                  </>
                ) : (
                  <>
                    <div className='flex h-10 w-48 mb-2 mt-2   text-md'>
                      <div
                        className=' gap-3 flex justify-start  text-white bg-violet-500 px-2 items-center  w-[100%] rounded-md'
                        onClick={() => setOpenSelectTech(true)}
                      >
                        <AddOutlinedIcon className=' ' style={{ fontSize: '1rem' }} />{' '}
                        <div className='flex  right-0'> Add Project</div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* <Switch
          checked={darkMode}
          onChange={()=>handleChange()}
          className='group inline-flex h-6 w-12 items-center rounded-full border border-neutral-800 bg-[#101010] transition data-[checked]:bg-green-500'
        >
          <span className='size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6' />
        </Switch> */}

      <div className='py-1.5  justify-end items-center gap-4 flex  '>
        {/* <Switch
          checked={darkMode}
          onChange={()=>handleChange()}
          className='group inline-flex h-6 w-12 items-center rounded-full dark:border dark:border-neutral-800 bg-[#101010] transition data-[checked]:bg-green-500'
        >
          <span className='size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6'/>
        </Switch> */}

        <div
          className='p-1.5 rounded-[63.2px] justify-start items-center gap-1.5 flex cursor-pointer'
          onClick={() => window.open('https://calendly.com/avi-tria/meetup', '_blank')}
        >
          <img
            src={mode == 'light' ? '/images/logos/Frame.svg' : '/images/logos/que_white.png'}
            className='w-4.8 h-4.8 relative text-black'
          />
          <div className="text-black/opacity-80 text-[0.875rem] font-normal font-['Roboto'] leading-snug">
            Need help?
          </div>
        </div>
        <div className='p-1.5 rounded-[63.2px] borde justify-start items-center gap-1.5 flex cursor-pointer'>
          <div className='w-4.8 h-4.8 justify-center items-center flex'>
            <img
              src={mode == 'light' ? '/images/logos/book.svg' : '/images/logos/book_white.png'}
              className='w-4.8 relative'
            />
          </div>
          <div className="text-black/opacity-80 text-[0.875rem] font-normal font-['Roboto'] leading-snug">
            <a href='https://docs.tria.so/' target='blank'>
              Docs
            </a>
          </div>
        </div>
        {/* <div className='cursor-pointer ' onClick={()=>handleChange()}>
      { mode=="dark"? 
      <div className='hover:bg-[#5E5E5E] bg-stone-800 p-1.5 flex justify-center items-center rounded-full transition transform active:scale-95 '><WbSunnyIcon  /></div>
      :
      <div className='hover:bg-stone-300 bg-stone-200 rotate-[130deg] p-1.5 flex justify-center items-center rounded-full transition transform active:scale-95'> <Brightness3Icon /></div>

     }
     </div> */}
        <div className='p-1.5 rounded-[63.2px]  justify-start items-center gap-1.5 flex'>
          {/* <div className="text-black/opacity-80 text-[0.875rem] font-normal font-['Roboto'] leading-snug cursor-pointer">{userData?.email}</div> */}
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingTop: 3, marginRight: 3 }}> */}
          <UserDropdown />
          {/* </Box> */}
        </div>
      </div>

      {/* dialog add Project */}

      <Dialog className=' rounded-[50px] w-[100%]  ' open={openSelectTech} onClose={handleCloseSelectTech}>
        <SelectTechstack
          setOpenProjectDetails={setOpenProjectDetails}
          handleClose={handleCloseSelectTech}
          projectDetails={projectDetails}
          setProjectDetails={setProjectDetails}
          setOpenSelectTech={setOpenSelectTech}
        />
      </Dialog>

      <Dialog className=' rounded-[50px]' open={openProjectDetails} onClose={handleBack}>
        <ProjectDetails
          projectDetails={projectDetails}
          title='Create new project'
          buttonText='Create Project'
          handleClose={() => {
            setOpenProjectDetails(false)
            setOpenSelectTech(false)
          }}
          variant={variant}
        />
      </Dialog>
    </div>
  )
}

export default AppBarContent
