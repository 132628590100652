// ** Type Imports
import { NavLink, NavGroup, LayoutProps, NavSectionTitle } from 'src/@core/layouts/types'

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink'
import VerticalNavGroup from './VerticalNavGroup'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import { useSelector } from 'react-redux'
import withRoleProtection from 'src/utils/protectedRoutes'
import CustomToggleButton from 'src/views/ui/Switch'

interface Props {
  parent?: NavGroup
  navHover?: boolean
  navVisible?: boolean
  groupActive: string[]
  isSubToSub?: NavGroup
  currentActiveGroup: string[]
  navigationBorderWidth: number
  settings: LayoutProps['settings']
  saveSettings: LayoutProps['saveSettings']
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
  verticalNavItems?: LayoutProps['verticalLayoutProps']['navMenu']['navItems']
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle
  if ((item as NavGroup).children) return VerticalNavGroup

  return VerticalNavLink
}

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props
  console.log("verticalNavItems",verticalNavItems);
  
  const userData = useSelector((state: any) => state.user.userProfileData);
  const userRole = userData?.role?.name;
  console.log("userRole",userRole);
  
  
  const RenderMenuItems = verticalNavItems?.map((item: NavGroup | NavLink | NavSectionTitle, index: number) => {
    
    if (item.admin && userRole !== 'triaadmin' ) {
      return null; // Don't render this item if the user is not 'triaadmin'
    }

    const TagName: any = resolveNavItemComponent(item)
    if(item.title =="Settings"){
      return (
        <div className='mt-2'>
         <hr className='mr-8'/>
          <TagName {...props} key={index} item={item} />
        </div>
      );
    }
    if(item.title =="Dark mode"){
      return (
        <div className='mt-2 flex '>
         
          <TagName {...props} key={index} item={item} />
          <div className='mr-12 mt-3'>
            <CustomToggleButton/>
          </div>
        </div>
      );
    }
    else{
    return <TagName {...props} key={index} item={item} />
    }
  })



  return <>{RenderMenuItems}</>
}
//export default withRoleProtection(VerticalNavItems, ['triaadmin']);
 export default VerticalNavItems
