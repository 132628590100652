import { useEffect, useState } from 'react'
import { useAuth } from 'src/hooks/useAuth'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
import CustomTextField from 'src/@core/components/mui/text-field'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { updateSelectedClientId } from 'src/redux/user/userSlice'
import { useSelector } from 'react-redux'
import { width } from '@mui/system'
import { OrganizationType } from 'src/types/apps/userTypes'

const ClientsDropdownMenu = () => {
  const auth = useAuth()
  const [organizationData, setOrganizationData] = useState<OrganizationType[]>([])
  const [loading, setLoading] = useState(true)
  const [currentClient, setCurrentClient] = useState('')
  const dispatch = useDispatch()

  const fetchData = async () => {
    try {
      const data = await auth.getClients()
      if (data.success) {
        console.log('data clients', data)
        setOrganizationData(data.organizations);
        // const clientId = window.localStorage.getItem('clientId')
        // console.log('clientId', clientId)
        //@ts-ignore
        // const currentClientData = data?.data?.filter(client => client?.clientId == clientId)
        // console.log('currentClient', currentClientData)
        //@ts-ignore
        // setCurrentClient(currentClientData[0]?.clientId || 'all')
        // if (!clientId) {
        //   window.localStorage.setItem('clientId','all')
        // window.location.reload()
        // }
        // dispatch(updateSelectedClientId( data.data[0]?.clientId))
        // localStorage.setItem('clientId', data.data[0]?.clientId)
        // toast.success(data.message)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleClientChange = (e: any) => {
    console.log('e.target.value', e.target.value)
    if (e.target.value != 'all') {
      // window.localStorage.setItem('clientId', e.target.value)
      // window.location.reload() 
      setCurrentClient(e.target.value)
      dispatch(updateSelectedClientId(e.target.value))
    } else {
      // window.localStorage.setItem('clientId', 'all')
      // window.location.reload()
      setCurrentClient('all')
      console.log("setting all");
      dispatch(updateSelectedClientId("all"))
    }
  }
  console.log('auth?.projectId', auth?.projectId)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5rem',
        marginBottom: '1rem',
        minWidth: '14rem',
        marginTop: '1rem'
      }}

    >
      {loading ? (
        <p></p>
      ) : (
        <CustomTextField
          sx={{
            width: '15rem',



          }}
          select
          fullWidth

          // label='Select Project'
          //@ts-ignore
          value={currentClient ? currentClient : 'all'}
            onChange={handleClientChange}
          SelectProps={{
            MenuProps: {
              className: 'max-h-60  custom-scrollbaer p-2 ',
              MenuListProps: {
                className: 'custom-scrollbar'
              },
              PaperProps: {
                className: 'custom-scrollbar  '
              }
            }
          }}

        >

          <MenuItem key='all' value='all'>
            All Clients
          </MenuItem>

            {organizationData &&
              organizationData.map((organization) => (
              <MenuItem
                sx={{
                  width: '13rem'


                }}
                //Lookup
                  key={organization.id} value={organization.id}>
                  {organization?.organizationDetails?.name || organization?.email}
              </MenuItem>
            ))}

        </CustomTextField>
      )}
    </Box>
  )
}

export default ClientsDropdownMenu
