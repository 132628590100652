import { Dialog, CircularProgress, Select, MenuItem, InputLabel, Button } from '@mui/material'
import { url } from 'inspector'
import React, { useEffect, useState } from 'react'
import { platformTypeInProjectDropdown } from 'src/constants'
import FileUpload from 'src/pages/components/fileUpload/FileUpload'
import Loader from '../loader/Loader'
import { useForm } from 'react-hook-form'
import CustomTextField from 'src/@core/components/mui/text-field'
import toast from 'react-hot-toast'
import { useAuth } from 'src/hooks/useAuth'
import { useDispatch } from 'react-redux'
import { addeNewProject, updateSelectedEnviornment, updateSelectedProject } from 'src/redux/user/userSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { Icon } from '@iconify/react'
import { ProjectDetailsForm } from 'src/types'
interface ProjectForm {
  id?: string
  name: string
  imageUrl: string
  techStack: string
  createdFirstProject?: boolean
  whitelistedOrigin?: string[]
}
interface ProjectDetailsProps {
  handleClose: () => void
  projectDetails: ProjectDetailsForm
  title: string
  buttonText: string
  variant?: string
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({ handleClose, projectDetails, title, buttonText, variant }) => {
  const auth = useAuth()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [imageUploadOpen, setImageUploadOpen] = useState(false)
  const [fileUrl, setFileUrl] = useState('')
  const [newOrigin, setNewOrigin] = useState('')

  const defaultFormValues: ProjectForm = {
    name: projectDetails?.name || '',
    techStack: projectDetails?.techStack || '',
    imageUrl: projectDetails?.imageUrl || '',
    whitelistedOrigin: projectDetails?.whitelistedOrigin || []
  }

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    setValue,
    formState: { errors },
    control
  } = useForm({
    defaultValues: defaultFormValues
  })

  const watchImg = watch('imageUrl')
  const whitelistedOrigin = watch('whitelistedOrigin')

  //useEffect to update imageUrl after
  useEffect(() => {
    if (!(fileUrl === '')) {
      setValue('imageUrl', fileUrl)
    }
    setImageUploadOpen(false)
  }, [fileUrl])

  //Watch Image Url
  useEffect(() => {
    if (watchImg !== '') {
      validateImageUrl(watchImg)
    } else {
      clearErrors('imageUrl')
    }
  }, [watchImg])

  const validateImageUrl = async (url: string) => {
    const img = new Image()
    img.src = url
    img.onload = function () {
      console.log('The image loaded successfully')
      clearErrors('imageUrl')
    }
    img.onerror = function () {
      setError('imageUrl', {
        type: 'manual',
        message: 'Invalid image url'
      })
    }
  }

  const validateCorsUrl = () => {
    const regex = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
    if (newOrigin !== '') {
      console.log('Checking web')
      if (regex.test(newOrigin)) {
        clearErrors('whitelistedOrigin')
      } else {
        setError('whitelistedOrigin', {
          type: 'manual',
          message: 'Invalid website url'
        })
      }
    }
  }

  const addNewOrigin = () => {
    validateCorsUrl()
    if (whitelistedOrigin === undefined) return
    if (!newOrigin || errors.whitelistedOrigin?.message) {
      return
    }
    if (whitelistedOrigin.includes(newOrigin)) {
      setError('whitelistedOrigin', {
        type: 'manual',
        message: 'Origin Already Exists'
      })
      return
    }
    setValue('whitelistedOrigin', [...whitelistedOrigin, newOrigin])
    setNewOrigin('')
  }

  const onSubmit = async (data: ProjectForm) => {
    const { name, imageUrl, techStack, whitelistedOrigin } = data
    if (!name || !imageUrl || !techStack || !whitelistedOrigin) {
      toast.error('Please fill all the details')
      return
    }
    try {
      setLoading(true)
      const saveProjectDetails: any = {
        name,
        imageUrl,
        techStack,
        whitelistedOrigin: whitelistedOrigin,
        createdFirstProject: userData?.organization?.createdFirstProject || false
      }
      if (projectDetails.id) {
        saveProjectDetails.id = projectDetails.id
      }

      //Request
      const response = await auth.saveProjectDetails(saveProjectDetails)
      if (response.success) {
        const newProject = response.project
        toast.success('project saved successfully')
        dispatch(addeNewProject(newProject))
        handleClose()
        dispatch(updateSelectedProject(newProject))
        dispatch(updateSelectedEnviornment('sandbox'))
      } else {
        toast(response?.error?.message || response?.message)
      }

      setLoading(false)
    } catch (error: any) {
      console.log('Error saving the Project Details:', error)
      toast.error(error?.response?.data?.message)
      setLoading(false)
    }
  }

  const mode = useSelector((state: RootState) => state.user.darkMode)
  const userData = useSelector((state: RootState) => state.user.userProfileData)

  return (
    <div
      className={`${
        mode == 'dark' ? 'bg-dark' : 'bg-white'
        }  md:w-[500px] w-[90%] h-full  rounded-xl  dark:bg-[#24303f]  mx-auto px-[1rem] py-[1.5rem] flex-col justify-end items-center max-h-[800px] hide-scrollbar overflow-y-auto `}
    >
      {loading ? (
        <div className=' justify-center md:w-[480px] h-[450px] w-[90%]  mx-auto px-[1rem] flex items-center '>
          <CircularProgress color='secondary' className=' w-52 h-52 ' />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='self-stretch flex-col justify-center items-start   flex'
        >
          <div className=' w-[100%] flex justify-between items-center gap-[0.7rem] '>
            <div className=' mb-5  '></div>

            <div className="text-center dark:text-[#979BAF] text-[1.6rem] font-semibold font-['Roboto'] leading-[2.1rem]">
              {title}
            </div>

            {variant !== 'FirstProject' ? (
              <div className='w-[1.6rem] h-[1.6rem] mb-7 relative cursor-pointer ' onClick={handleClose}>
                <div className='w-[1.6rem] h-[1.6rem] left-[0.01rem] top-0 absolute'>
                  <img src={mode == 'light' ? '/images/logos/cross.png' : '/images/logos/cross_white.png'} alt='' />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="w-[100%] dark:text-[#979BAF]  text-center mt-2  text-stone-950/opacity-60 text-[1rem] font-normal font-['Roboto'] leading-[1.4rem]">
            Add a few more details to get started on your latest project
          </div>
          <div className='self-stretch  h-[50%] flex-col justify-center items-start gap-[1.2rem] flex'>
            <div className=' w-[100%]  self-stretch h-[65%] flex-col justify-center items-start gap-[0rem] mb-3 flex'>
              <div className='self-stretch  justify-center items-center gap-[1rem] inline-flex'>
                <div className='w-14 h-14 rounded-full bg-[#FFFFFF1A] flex justify-center items-center '>
                  {!errors.imageUrl && watchImg ? (
                    <img src={watchImg} alt='' className='w-[100%] h-14 rounded-full' />
                  ) : (
                    <img className='w-[100%]' src='/images/logos/default.png' alt='' />
                  )}
                </div>

                <div className='grow shrink basis-0 py-[1.4rem] flex-col justify-center items-start    inline-flex'>
                  <div className='grow shrink basis-0 self-stretch justify-start items-center gap-[0.8rem] flex'>
                    <div className='  w-[70%] mt-3 justify-start items-start flex flex-col'>
                      <CustomTextField
                        className='dark:bg-[#FFFFFF08]'
                        fullWidth
                        placeholder='Image Url'
                        {...register('imageUrl')}
                      />
                      <p className='text-xs mt-[0.3rem] text-gray-400'>Preferred size: 128x128 px</p>
                    </div>
                    <div
                      onClick={() => setImageUploadOpen(true)}
                      className='shrink basis-0  self-stretch  bg-stone-950/opacity-10  justify-center items-center flex'
                    >
                      <button type='button' className='justify-center   items-center flex '>
                        <div
                          className={`grow text-center py-[0.6rem] w-[6rem] hover:dark:bg-[#FFF] ${
                            mode == 'dark' ? 'bg-[#FFFFFF1A] text-white' : 'bg-gray-200 text-stone-950'
                          }  dark:bg-[#FFFFFF1A] hover:dark:text-[black]  hover:bg-gray-300 rounded-[6rem]   dark:text-white  opacity-80 text-md  font-['Roboto'] leading-[1.6rem]`}
                        >
                          Upload
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p className='text-red-500 text-xs mt-[-1.1rem] pl-4 mb-4 h-3'>{errors.imageUrl?.message}</p>
              <div className='w-[100%] flex flex-col'>
                <p className='text-[0.86rem]'>Project Name</p>
                <CustomTextField
                  className='dark:bg-[#FFFFFF08]'
                  fullWidth
                  //label='Project Name'
                  placeholder=''
                  {...register('name')}
                />
              </div>
            </div>
            <div className='self-stretch h-[9.6%] flex-col justify-center items-start gap-[0.7rem] flex mb-2'>
              <div className='relative w-[100%] flex-col justify-center items-center gap-[0.7rem] flex'>
                <div className='w-[100%] flex flex-col'>
                  <p className='text-[0.86rem]'>Tech Stack</p>
                  <CustomTextField
                    className='dark:bg-[#FFFFFF08]  '
                    select
                    fullWidth
                    defaultValue=''
                    //label='Tech Stack'
                    value={watch('techStack')}
                    {...register('techStack')}
                  >
                    <MenuItem value='React'>React</MenuItem>
                    <MenuItem value='Native'>React Native</MenuItem>
                    <MenuItem value='Web'>Web SDK</MenuItem>
                  </CustomTextField>
                </div>
              </div>
            </div>
            <div className='w-[100%] flex flex-col'>
              <p className='text-[0.86rem]'>Whitelist Origin</p>
              <div className='flex gap-3'>
                <CustomTextField
                  className='dark:bg-[#FFFFFF08]'
                  fullWidth
                  type='text'
                  //label='Website Url'
                  placeholder='https://example.com, yourapp://auth'
                  value={newOrigin}
                  onChange={e => setNewOrigin(e.target.value)}
                  onBlur={validateCorsUrl}
                  onFocus={() => clearErrors('whitelistedOrigin')}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      addNewOrigin()
                      return
                    }
                  }}
                />
                <Button
                  onClick={() => {
                    addNewOrigin()
                  }}
                >
                  Add
                </Button>
              </div>
              {<p className='text-red-500 h-3 text-xs pl-4'>{errors.whitelistedOrigin?.message}</p>}
            </div>
            <div className='w-full flex gap-2 flex-wrap'>
              {whitelistedOrigin &&
                whitelistedOrigin.map((data, index) => {
                  return (
                    <div className='p-2 mx-2 bg-slate-400 rounded-md flex items-center gap-2'>
                      <span>{data}</span>{' '}
                      <Icon
                        onClick={() => {
                          setValue(
                            'whitelistedOrigin',
                            whitelistedOrigin.filter((_, i) => i !== index)
                          )
                        }}
                        className=' cursor-pointer'
                        icon='entypo:cross'
                      ></Icon>
                    </div>
                  )
                })}
            </div>
          </div>

          <div className='  flex-col w-[100%] justify-center items-center mt-2 gap-[0.7rem] flex'>
            <button
              type='submit'
              className={` ${
                mode == 'dark' ? 'bg-[#FFFFFF08]' : 'bg-stone-200'
              } dark:bg-[#FFFFFF08] w-[100%]   hover:bg-stone-30 hover:dark:bg-gray-600 rounded-[6rem] justify-center items-center inline-flex`}
            >
              <div className='justify-center items-center flex w-[100%]'>
                <div className="text-center self-stretch h-[9.6%] w-[100%]  px-[1.6rem] py-[0.7rem] rounded-[6rem] hover:dark:bg-[#FFF]  dark:text-[#979BAF] dark:hover:text-[#101010]  text-stone-950/opacity-30 text-[1rem] font-semibold font-['Roboto'] leading-[1.4rem]">
                  {buttonText}
                </div>
              </div>
            </button>
          </div>
        </form>
      )}
      <Dialog className=' dark-100 rounded-[50px]' open={imageUploadOpen} onClose={() => setImageUploadOpen(false)}>
        <FileUpload setFileUrl={setFileUrl} />
      </Dialog>
    </div>
  )
}
export default ProjectDetails
