// import React, { useState } from 'react';
// import { Switch } from '@mui/material'; // Using MUI for the switch component

// const ModeToggler: React.FC = () => {
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   const handleToggle = () => {
//     setIsDarkMode(!isDarkMode);
//     // Add your logic to enable/disable dark mode here
//   };

//   return (
//     <div className="flex items-center space-x-2">
//       <span>Dark Mode</span>
//       <Switch checked={isDarkMode} onChange={handleToggle} />
//     </div>
//   );
// };

// export default ModeToggler;
// CustomToggleButton.tsx
import React, { useEffect, useState } from 'react';
import { Mode } from 'src/@core/layouts/types'
import { styled } from '@mui/material/styles';
import { Switch } from '@headlessui/react'
import { useSettings } from 'src/@core/hooks/useSettings';
// Styled MUI Switch


const CustomToggleButton: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
 
  
  const { settings, saveSettings } = useSettings()
  useEffect(()=>{
    if (settings.mode === 'light') {
       
        setIsDarkMode(false)
      } else {
       
        setIsDarkMode(true)
      }
  })
  const handleModeChange = (mode: Mode) => {
    saveSettings({ ...settings, mode: mode })
  }

  const handleModeToggle = () => {
    if (settings.mode === 'light') {
      handleModeChange('dark' as Mode)
      setIsDarkMode(true)
    } else {
      handleModeChange('light' as Mode)
      setIsDarkMode(false)
    }
    
  }
  return (
    <div className="flex items-center space-x-2">
      {/* <span>{isDarkMode ? 'Dark Mode' : 'Light Mode'}</span> */}
      {/* <CustomSwitch
        checked={isDarkMode}
        onChange={handleToggle}
        color="primary"
      /> */}
       <Switch
      checked={isDarkMode}
      onChange={handleModeToggle}
      className="group relative flex h-6 w-11 pl-1 items-center cursor-pointer rounded-full bg-gray-300 dark:bg-white/10 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10"
    >
      <span
        aria-hidden="true"
        className="pointer-events-none inline-block size-[18px]   translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-[90%]"
      />
    </Switch>
    </div>
  );
};

export default CustomToggleButton;``
 